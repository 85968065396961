import React from 'react'
import './Button.scss'


type ButtonProps = {
    onClickButton?: React.MouseEventHandler<HTMLButtonElement>
    disabled?: boolean
    children?: JSX.Element | JSX.Element[] | string
    classes?: Array<string>
}

const Button = (props: ButtonProps) => {
    let classes = ['Button']
    if (typeof props.classes !== 'undefined') {
        classes = [...classes, ...props.classes]
    }

    return (
        <button
            className={classes.join(' ')}
            onClick={props.onClickButton}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    )
}

export default Button