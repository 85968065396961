import React from 'react'
import FormRequest from '../FormRequest/FormRequest'
import {BrowserRouter as Router, Switch, Route, useParams} from 'react-router-dom'
import Error404 from '../Error404/Error404'


export default function App() {

    return (
        <Router>
            <Switch>
                <Route path="/client/:hash">
                    <RoutingFormRequest />
                </Route>
                <Route path="*">
                    <Error404 />
                </Route>
            </Switch>
        </Router>
    )
}


function RoutingFormRequest() {
    let { hash }: any = useParams()

    return (
        <FormRequest hash={hash} />
    )
}

