import React from 'react'
import './FormRequest.scss'
import Input from '../../components/UI/Input/Input'
import Select from '../../components/UI/Select/Select'
import Textarea from '../../components/UI/Textarea/Textarea'
import { createControl, isValid, validate, validateForm } from '../../funcs/formFramework'
import Button from '../../components/UI/Button/Button'
import Spinner from '../../components/UI/Spinner/Spinner'
import axios from 'axios'
import PropTypes from 'prop-types'
import Error404 from '../Error404/Error404'

const is = require('is_js')


export default class FormReact extends React.Component {
    static propTypes = {
        hash: PropTypes.string
    }

    constructor(props: any) {
        super(props)

        this.state.hash = props.hash
    }

    state = {
        hash: '',
        isFormValid: false,
        isFormSent: 0,
        commentShown: false,
        loaded: false,
        showError404: false,
        showSuccessPage: false,
        formControls: {
            region: createControl({
                name: 'region',
                label: 'Ближайшее место или район',
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),
            address: createControl({
                name: 'address',
                label: 'Адрес',
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),
            date: createControl({
                name: 'date',
                label: 'Планируемая дата работ',
                value: '-1',
                classes: ['css-field-empty'],
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),
            time: createControl({
                name: 'time',
                label: 'Желаемое время начала работ',
                value: '-1',
                classes: ['css-field-empty'],
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),

            phone: createControl({
                name: 'phone',
                label: 'Телефон',
                value: '',
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),

            mounting: createControl({
                name: 'mounting',
                label: 'Количество кроссов и муфт',
                value: '-1',
                classes: ['css-field-empty'],
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),
            outdoor: createControl({
                name: 'outdoor',
                label: 'из них на улице',
                value: '-1',
                classes: ['css-field-empty'],
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),
            newCount: createControl({
                name: 'newCount',
                label: 'Кол-во новых кроссов муфт',
                value: '-1',
                classes: ['css-field-empty'],
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),
            existedCount: createControl({
                name: 'existedCount',
                label: 'Кол-во существующих кроссов муфт',
                value: '-1',
                classes: ['css-field-empty'],
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),
            armor: createControl({
                name: 'armor',
                label: 'Сколько бронированных хвостов',
                value: '-1',
                classes: ['css-field-empty'],
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),
            welding: createControl({
                name: 'welding',
                label: 'Сколько всего сварок',
                value: '-1',
                classes: ['css-field-empty'],
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),
            correctLengths: createControl({
                name: 'correctLengths',
                label: 'Длина кабеля для разварки',
                value: '-1',
                classes: ['css-field-empty'],
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),
            weldingCount: createControl({
                name: 'weldingCount',
                label: 'Точное количество сварок',
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),

            trace: createControl({
                name: 'trace',
                label: 'Требуются рефлектограммы',
                value: '-1',
                classes: ['css-field-empty'],
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),
            measurement: createControl({
                name: 'measurement',
                label: 'Измерение',
                value: '-1',
                classes: ['css-field-empty'],
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),
            verification: createControl({
                name: 'verification',
                label: 'Свидетельство о поверке',
                value: '-1',
                classes: ['css-field-empty'],
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),

            expendables: createControl({
                name: 'expendables',
                label: 'Требуется ли привезти материалы?',
                value: '-1',
                classes: ['css-field-empty'],
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),

            payment: createControl({
                name: 'payment',
                label: 'Формат оплаты',
                value: '-1',
                classes: ['css-field-empty'],
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),

            comment: createControl({
                name: 'comment',
                label: 'Комментарий к заявке',
                onChangeField: (event: React.ChangeEvent<HTMLInputElement>) => this.changeFieldHandler(event),
                onBlurField: (event: React.FocusEvent<HTMLInputElement>) => this.blurFieldHandler(event),
            }),
        }
    }

    submitFormHandler: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault()

        const formControls: any = this.state.formControls
        if (!validateForm(formControls)) {
            let firstError = ''
            Object.keys(formControls).forEach((controlName) => {
                const control: any = formControls[controlName]
                control.checkValid = true

                if (!firstError && !isValid(validate(control.value, control.validation), control.hasValidation, control.checkValid)) {
                    firstError = controlName
                }
            })

            this.setState({formControls})

            if (firstError) {
                const element = document.querySelector(`.Input__Field[name="${firstError}"], .Textarea__Field[name="${firstError}"], .Select__Field[name="${firstError}"]`)
                if (element) {
                    const headerOffset = 60
                    const elementPosition = element.getBoundingClientRect().top
                    const offsetPosition = window.pageYOffset + elementPosition - headerOffset

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                    })
                }
            } else {
            }

            return
        }

        const data: any = {}
        Object.keys(formControls).forEach((controlName) => {
            const control: any = formControls[controlName]
            data[controlName] = control.value
        })

        this.sendForm(data)
    }

    async sendForm(data: any) {
        try {
            const response = await axios({
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                // url: 'http://yii-isvarka.ru/form-request/send',
                url: 'https://svarkacrm.ru/form-request/send',
                data: {
                    hash: this.state.hash,
                    ...data
                },
            })

            if (response.data.success) {
                this.setState({
                    isFormSent: response.data.id,
                })
            }
        } catch (e) {}
    }

    changeFieldHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const value = event.target.value
        const name = event.target.getAttribute('name')

        if (name) {
            this.setState((prevState: any) => {
                const formControls: any = { ...prevState.formControls }
                const control: any = { ...formControls[name] }

                if (event.target.tagName.toLowerCase() === 'select') {
                    const index = control.classes.indexOf('css-field-empty');
                    if (value === '-1') {
                        if (index === -1) {
                            control.classes.push('css-field-empty')
                        }
                    } else {
                        if (index !== -1) {
                            control.classes.splice(index, 1);
                        }
                    }
                }

                control.checkValid = false
                control.value = value
                control.valid = validate(control.value, control.validation)

                formControls[name] = control

                return {
                    formControls,
                    isFormValid: validateForm(formControls)
                }
            })
        }
    }

    blurFieldHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        const name = event.target.getAttribute('name')

        if (name) {
            this.setState((prevState: any) => {
                const formControls: any = { ...prevState.formControls }
                const control: any = { ...formControls[name] }

                control.checkValid = true
                formControls[name] = control

                return {formControls}
            })
        }
    }

    showCommentHandler = () => {
        this.setState(() => {
            return {commentShown : true}
        })
    }

    formatDate = (date: Date) => {
        let result = ''

        if (is.today(date)) {
            result += 'сегодня '
        } else if (is.tomorrow(date)) {
            result += 'завтра '
        }

        new Date(new Date().toDateString())

        result += `${Intl.DateTimeFormat('ru', {day: "2-digit"}).format(date)}.${Intl.DateTimeFormat('ru', {month: "2-digit"}).format(date)}`
        result += ` (${Intl.DateTimeFormat('ru', {weekday: "short"}).format(date)})`

        return result
    }

    formatTime = (date: Date) => {
        return `${Intl.DateTimeFormat('ru', {hour: "2-digit"}).format(date)}:00`
    }

    async componentDidMount() {
        try {
            const response = await axios({
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                // url: 'http://yii-isvarka.ru/form-request/access',
                url: 'https://svarkacrm.ru/form-request/access',
                data: { hash: this.state.hash },
            })

            if (!response.data.success) {
                this.setState({
                    loaded: true,
                    showError404: true,
                })
            } else {
                const formControls = this.state.formControls
                formControls.phone.value = response.data.phone

                this.setState({
                    loaded: true,
                    showError404: false,
                    formControls
                })
            }
        } catch (e) {}
    }

    render() {
        const formControls = this.state.formControls
        const today = new Date()
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)

        let dates = Array.from(Array(12).keys())
        let times = Array.from(Array(24).keys())
        let sixes = Array.from(Array(6).keys())
        let tens = Array.from(Array(10).keys())

        if (this.state.isFormSent) {
            return (
                <div className="FormRequest">
                    <div className="FormRequest__Container" style={{lineHeight: '28px'}}>
                        Ваша заявка принята.<br/>
                        <span style={{
                            display: 'inline-block',
                            fontSize: '30px',
                            margin: '16px 0 42px',
                            fontWeight: 'bold',
                        }}>Номер заявки {this.state.isFormSent}.</span><br/>
                        Наш оператор свяжется с вами в течение 15 минут по
                        указанному вами телефону и обсудит детали заказа.<br/><br/>
                        При изменениях в заказе повторное заполнение заявки не требуется.<br/>
                        Оператор свяжется с вами в любом случае.
                    </div>
                </div>
            )
        }

        return (
            <div className="FormRequest">
                <div className="FormRequest__Container">
                    {
                        !this.state.loaded
                            ? <div
                                style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                <Spinner/>
                            </div>
                            : (
                                this.state.showError404
                                    ? <Error404/>
                                    : <>
                                        <h1 className="FormRequest__Header">Заявка по сварке ВОЛС</h1>
                                        <form
                                            className="FormRequest__Form"
                                            onSubmit={this.submitFormHandler}
                                        >
                                            <div className="FormRequest__Chapter">
                                                <div className="FormRequest__Lefts" data-sticky-container="">
                                                    <div className="FormRequest__Title">
                                                        Место и&nbsp;время
                                                    </div>
                                                </div>
                                                <div className="FormRequest__Rights">
                                                    <div className="FormRequest__Row">
                                                        <div className="FormRequest__Column">
                                                            <Input {...formControls.region} />
                                                        </div>
                                                        <div className="FormRequest__Column">
                                                            <Input {...formControls.address} />
                                                        </div>
                                                    </div>
                                                    <div className="FormRequest__Row">
                                                        <div className="FormRequest__Column">
                                                            <Select {...formControls.date}>
                                                                <option value="-1">не определено</option>
                                                                <option value="1">{this.formatDate(today)}</option>
                                                                <option value="2">{this.formatDate(tomorrow)}</option>
                                                                <>{
                                                                    dates.map((value: any) => {
                                                                        const date = new Date()
                                                                        date.setDate(date.getDate() + 2 + value)

                                                                        return (
                                                                            <option
                                                                                value={value + 3}
                                                                                key={'val' + value}
                                                                            >
                                                                                {this.formatDate(date)}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }</>
                                                            </Select>
                                                        </div>
                                                        <div className="FormRequest__Column">
                                                            {
                                                                (['1', '2'].indexOf(formControls.date.value.trim()) !== -1)
                                                                && <Select {...formControls.time}>
                                                                    <option value="-1">не определено</option>
                                                                    <>{
                                                                        times.map((value: any) => {
                                                                            const date = new Date(new Date().toDateString())
                                                                            date.setHours(date.getHours() + value)

                                                                            return (
                                                                                <option
                                                                                    value={value}
                                                                                    key={'hour' + value}
                                                                                >
                                                                                    {this.formatTime(date)}
                                                                                </option>
                                                                            )
                                                                        })
                                                                    }</>
                                                                </Select>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="FormRequest__Chapter">
                                                <div className="FormRequest__Lefts" data-sticky-container="">
                                                    <div className="FormRequest__Title">
                                                        Контактные данные
                                                    </div>
                                                </div>
                                                <div className="FormRequest__Rights">
                                                    <div className="FormRequest__Row">
                                                        <div className="FormRequest__Column">
                                                            <Input {...formControls.phone} />
                                                        </div>
                                                        <div className="FormRequest__Column" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="FormRequest__Chapter">
                                                <div className="FormRequest__Lefts">
                                                    <div className="FormRequest__Title" data-sticky-container="">
                                                        Работы по&nbsp;сварке
                                                    </div>
                                                </div>
                                                <div className="FormRequest__Rights">
                                                    <div className="FormRequest__Row">
                                                        <div className="FormRequest__Column">
                                                            <Select {...formControls.mounting}>
                                                                <option value="-1">не знаю</option>
                                                                <option value="0">0 (нет)</option>
                                                                <>{
                                                                    tens.map(value => <option key={'val' + value}
                                                                                              value={(value + 1).toString()}>{value + 1}</option>)
                                                                }</>
                                                                <option value="more">&gt; (более)</option>
                                                            </Select>
                                                        </div>
                                                        <div className="FormRequest__Column">
                                                            {
                                                                (['-1', '0'].indexOf(formControls.mounting.value.trim()) === -1)
                                                                && <Select {...formControls.outdoor}>
                                                                    <option value="-1">не знаю</option>
                                                                    <option value="0">0 (нет)</option>
                                                                    <>{
                                                                        tens.map(value => <option key={'val' + value}
                                                                                                  value={(value + 1).toString()}>{value + 1}</option>)
                                                                    }</>
                                                                    <option value="more">&gt; (более)</option>
                                                                </Select>
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        (['-1', '0'].indexOf(formControls.mounting.value.trim()) === -1)
                                                        && <div className="FormRequest__Row">
                                                            <div className="FormRequest__Column">
                                                                <Select {...formControls.newCount}>
                                                                    <option value="-1">не знаю</option>
                                                                    <option value="0">0 (нет)</option>
                                                                    <>{
                                                                        tens.map(value => <option key={'val' + value}
                                                                                                  value={(value + 1).toString()}>{value + 1}</option>)
                                                                    }</>
                                                                    <option value="more">&gt; (более)</option>
                                                                </Select>
                                                            </div>
                                                            <div className="FormRequest__Column">
                                                                <Select {...formControls.existedCount}>
                                                                    <option value="-1">не знаю</option>
                                                                    <option value="0">0 (нет)</option>
                                                                    <>{
                                                                        tens.map(value => <option key={'val' + value}
                                                                                                  value={(value + 1).toString()}>{value + 1}</option>)
                                                                    }</>
                                                                    <option value="more">&gt; (более)</option>
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        (['-1', '0'].indexOf(formControls.mounting.value.trim()) === -1)
                                                        && <div className="FormRequest__Row">
                                                            <div className="FormRequest__Column">
                                                                <Select {...formControls.armor}>
                                                                    <option value="-1">не знаю</option>
                                                                    <option value="0">0 (нет)</option>
                                                                    <>{
                                                                        sixes.map(value => <option key={'val' + value}
                                                                                                   value={(value + 1).toString()}>{value + 1}</option>)
                                                                    }</>
                                                                    <option value="more">&gt; (более)</option>
                                                                </Select>
                                                            </div>
                                                            <div className="FormRequest__Column">
                                                                <Select {...formControls.correctLengths}>
                                                                    <option value="-1">не знаю</option>
                                                                    <option value="1">все короткие</option>
                                                                    <option value="2">некоторые короткие</option>
                                                                    <option value="3">все длинные</option>
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        (['-1', '0'].indexOf(formControls.mounting.value.trim()) === -1)
                                                        && <div className="FormRequest__Row">
                                                            <div className="FormRequest__Column">
                                                                <Select {...formControls.welding}>
                                                                    <option value="-1">не знаю</option>
                                                                    <option value="exact">знаю точное количество сварок</option>
                                                                    <option value="1">1-8</option>
                                                                    <option value="2">4-16</option>
                                                                    <option value="3">12-24</option>
                                                                    <option value="4">16-48</option>
                                                                    <option value="5">32-64</option>
                                                                    <option value="more">&gt; (более)</option>
                                                                </Select>
                                                            </div>
                                                            <div className="FormRequest__Column">
                                                                {
                                                                    (['exact'].indexOf(formControls.welding.value.trim()) !== -1)
                                                                    && <Input {...formControls.weldingCount} />
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="FormRequest__Chapter">
                                                <div className="FormRequest__Lefts" data-sticky-container="">
                                                    <div className="FormRequest__Title">
                                                        Рефлекто&shy;граммы
                                                    </div>
                                                </div>
                                                <div className="FormRequest__Rights">
                                                    <div className="FormRequest__Row">
                                                        <div className="FormRequest__Column">
                                                            <Select {...formControls.trace}>
                                                                <option value="-1">не знаю</option>
                                                                <option value="0">нет</option>
                                                                <option value="1">да, одномод</option>
                                                                <option value="2">да, многомод</option>
                                                            </Select>
                                                        </div>
                                                        <div className="FormRequest__Column"/>
                                                    </div>
                                                    {
                                                        (['-1', '0'].indexOf(formControls.trace.value.trim()) === -1)
                                                        && <div className="FormRequest__Row">
                                                            <div className="FormRequest__Column">
                                                                <Select {...formControls.measurement}>
                                                                    <option value="-1">не знаю</option>
                                                                    <option value="1">с одной стороны</option>
                                                                    <option value="2">с двух сторон</option>
                                                                </Select>
                                                            </div>
                                                            <div className="FormRequest__Column">
                                                                <Select {...formControls.verification}>
                                                                    <option value="-1">не знаю</option>
                                                                    <option value="0">не требуется</option>
                                                                    <option value="1">требуется</option>
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="FormRequest__Chapter">
                                                <div className="FormRequest__Lefts" data-sticky-container="">
                                                    <div className="FormRequest__Title">
                                                        Материалы
                                                    </div>
                                                </div>
                                                <div className="FormRequest__Rights">
                                                    <div className="FormRequest__Row">
                                                        <div className="FormRequest__Column">
                                                            <Select {...formControls.expendables}>
                                                                <option value="-1">не знаю</option>
                                                                <option value="0">нет</option>
                                                                <option value="1">да</option>
                                                            </Select>
                                                        </div>
                                                        <div className="FormRequest__Column"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="FormRequest__Chapter">
                                                <div className="FormRequest__Lefts" data-sticky-container="">
                                                    <div className="FormRequest__Title">
                                                        Оплата
                                                    </div>
                                                </div>
                                                <div className="FormRequest__Rights">
                                                    <div className="FormRequest__Row">
                                                        <div className="FormRequest__Column">
                                                            <Select {...formControls.payment}>
                                                                <option value="-1">не знаю</option>
                                                                <option value="0">Безнал</option>
                                                                <option value="12">На карту Альфабанка</option>
                                                                <option value="13">На карту Тинькофф</option>
                                                                <option value="14">На карту ВТБ</option>
                                                                <option value="11">На карту Сбербанк</option>
                                                                <option value="1">Наличные</option>
                                                            </Select>
                                                        </div>
                                                        <div className="FormRequest__Column"/>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                this.state.commentShown
                                                    ? <div className="FormRequest__Chapter">
                                                        <div className="FormRequest__Lefts" data-sticky-container="">
                                                            <div className="FormRequest__Title">
                                                                Заметка
                                                            </div>
                                                        </div>
                                                        <div className="FormRequest__Rights">
                                                            <div className="FormRequest__Row">
                                                                <div className="FormRequest__Column">
                                                                    <Textarea {...formControls.comment} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : <div className="FormRequest__CommentShow">
                                                    <span
                                                        className="FormRequest__CommentShowLink"
                                                        onClick={this.showCommentHandler}
                                                    >Добавить комментарий</span>
                                                    </div>
                                            }
                                            <div className="FormRequest__Button">
                                                <Button>Отправить заявку</Button>
                                            </div>
                                        </form>
                                    </>
                            )
                    }
                </div>
            </div>
        )
    }
}