import React from 'react'
import './Input.scss'
import {isValid, FormControlProps, validate} from '../../../funcs/formFramework'


const Input = (props: FormControlProps) => {
    const name = props.name
    const type = props.type || 'text'
    const htmlFor = `${type}-${Math.random()}`
    const valid = isValid(validate(props.value, props.validation), props.hasValidation, props.checkValid)
    let classes = ['Input']

    if (typeof props.classes !== 'undefined') {
        classes = [...classes, ...props.classes]
    }
    if (!valid) {
        classes.push('Input_errored')
    }

    return (
        <div className={classes.join(' ')}>
            <label
                className="Input__Label"
                htmlFor={htmlFor}
            >
                {props.label}
            </label>
            <div className="Input__Container">
                <input
                    type={type}
                    name={name}
                    id={htmlFor}
                    className="Input__Field"
                    value={props.value || ''}
                    onChange={props.onChangeField}
                    onBlur={props.onBlurField}
                />
            </div>
            {
                !valid
                    && <div className="Input__Error">{props.errorMessage || 'Укажите значение'}</div>
            }
        </div>
    )
}

export default Input