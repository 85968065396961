import React from 'react'

export type FormControlProps = {
    name: string
    label: string
    valid: boolean
    hasValidation: boolean
    checkValid: boolean
    value: string
    classes?: Array<string>
    errorMessage?: string
    type?: string
    validation?: Object
    children?: JSX.Element | JSX.Element[]
    onChangeField?: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    onBlurField?: React.FocusEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
}

export function createControl(config: any): FormControlProps {
    return {
        valid: !config.validation,
        hasValidation: !!config.validation,
        checkValid: false,
        value: '',
        onChangeField: () => {},
        ...config,
    }
}

export function validate(value: string, validation?: any) {
    if (!validation) {
        return true
    }

    let isValid = true

    if (validation.required) {
        isValid = value.trim() !== '' && isValid
    }

    return isValid
}

export function isValid(valid: boolean, hasValidation: boolean, checkValid: boolean): boolean {
    return !(!valid && checkValid && hasValidation)
}

export function validateForm(formControls: any) {
    let isFormValid = true

    for (let control in formControls) {
        if (formControls.hasOwnProperty(control)) {
            isFormValid = validate(formControls[control].value, formControls[control].validation) && isFormValid
        }
    }

    return isFormValid
}