import React from 'react'
import './Error404.scss'


export default function Error404() {
    return (
        <div className="Error404">
            <div className="Error404__Container">
                <h1 className="Error404__Header">Страница не найдена</h1>
                <div className="Error404__Text">По данному адресу ничего не найдено.</div>
            </div>
        </div>
    )
}
