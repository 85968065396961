import React from 'react'
import './Select.scss'
import {isValid, FormControlProps, validate} from '../../../funcs/formFramework'

const Select = (props: FormControlProps) => {
    const name = props.name
    const htmlFor = `select-${Math.random()}`
    const valid = isValid(validate(props.value, props.validation), props.hasValidation, props.checkValid)
    let classes = ['Select']

    if (typeof props.classes !== 'undefined') {
        classes = [...classes, ...props.classes]
    }
    if (!valid) {
        classes.push('Select_errored')
    }

    return (
        <div className={classes.join(' ')}>
            <label
                className="Select__Label"
                htmlFor={htmlFor}
            >
                {props.label}
            </label>
            <div className="Select__Container">
                <select
                    name={name}
                    id={htmlFor}
                    className="Select__Field"
                    value={props.value || ''}
                    onChange={props.onChangeField}
                    onBlur={props.onBlurField}
                >
                    {props.children}
                </select>
            </div>
            {
                !valid
                    && <div className="Select__Error">{props.errorMessage || 'Выберите значение'}</div>
            }
        </div>
    )
}

export default Select